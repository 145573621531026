@import '@yaireo/tagify/dist/tagify.css';

@media print {
  .print-break {
    page-break-after: always;
  }
}

.production-space-heading {
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
}